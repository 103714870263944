import React, { useState, useEffect } from "react";
import Dashboard from "../../components/layout/Dashboard";
import FullScreenLoader from "../../components/shared/FullScreenLoader";
import { Box, Checkbox, Typography } from "@mui/material";
import { StyledButton, StyledLoadingButton } from "../../styles/Button";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Close, Done } from "@mui/icons-material";
import html2canvas from "html2canvas";
import SendingEmail from "../../components/admin/verify/SendingEmail";
import jsPDF from "jspdf";
const Verify = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailDetails, setEmailDetails] = useState({});
  const [checked, setChecked] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    fetchEmailDetails(controller.signal);

    // Set up an interval to call fetchEmailDetails every 100 milliseconds
    const intervalId = setInterval(() => {
      if (isMounted) {
        fetchEmailDetails(controller.signal);
      } else {
        clearInterval(intervalId); // Clear the interval if the component is unmounted
      }
    }, 10000); // 100 milliseconds

    // Cleanup the interval and abort the fetch if the component unmounts
    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(intervalId);
    };
  }, []);
  const fetchEmailDetails = async (signal) => {
    try {
      const { data } = await axiosPrivate.get("/file-processing", {
        signal,
      });
      setEmailDetails(data.data);
      console.log(data.data, "data");
    } catch (e) {
      setEmailDetails({});
      console.log(e, "e");
    }
  };

  const onSendEmail = async () => {
    setIsSuccess(false);
    setEmailSent(false);
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.post("/file-processing/send-email", {
        isLink: checked,
      });
      await fetchEmailDetails();
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsSuccess(true);
      setIsLoading(false);
    }
  };

  const downloadPDF = () => {
    const capture = document.querySelector(".actual-receipt");
    setLoader(true);
    // Capture the content at its current dimensions without scaling
    html2canvas(capture, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4", true);
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      console.log(componentHeight);
      console.log(componentHeight);
      doc.addImage(imgData, "PNG", 10, 30, 150, 100);
      setLoader(false);
      doc.save("bill.pdf");
    });
  };

  return (
    <>
      <Dashboard>
        {isLoading && <FullScreenLoader />}

        {isSuccess ? (
          <div className="actual-receipt">
            <Box>
              <SendingEmail setEmailSent={setEmailSent} emailSent={emailSent} />
              <Typography component="div" variant="h6">
                With Payment Link ? {checked ? "Yes" : "No"}
              </Typography>
              <StyledButton
                variant="contained"
                onClick={() => navigate("/grm/admin")}
                sx={{ my: 2 }}
              >
                Return to admin
              </StyledButton>
              <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
                <Typography component="div" variant="body1" sx={{ mr: 2 }}>
                  Print this page
                </Typography>
                <StyledLoadingButton variant="contained" onClick={downloadPDF}>
                  {loader ? <span>Downloading</span> : <span>Print</span>}
                </StyledLoadingButton>
              </Box>
            </Box>
          </div>
        ) : (
          <div>
            <Typography component="div" variant="h5">
              You are requested to
            </Typography>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              There are currently <b>{emailDetails.totalUnsentEmails || 0} </b>{" "}
              email addresses in the queue with a due date of:{" "}
              <b>{emailDetails.dueDate} </b>
            </Typography>
            <Box>
              <Typography
                component="div"
                variant="body1"
                sx={{ py: 1 }}
              ></Typography>
            </Box>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              Email were last sent on
              <b> {emailDetails.lastEmailData} </b>
            </Typography>
            <Box>
              <Typography component="div" variant="body1" sx={{ py: 1 }}>
                Do you want to include the payment link in the emails
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                {checked ? (
                  <Done sx={{ color: "green" }} />
                ) : (
                  <Close sx={{ color: "red" }} />
                )}
              </Box>
            </Box>

            <Box>
              <Typography component="div" variant="body1" sx={{ py: 1 }}>
                if this is corrent click Send
              </Typography>
              <StyledButton variant="contained" onClick={onSendEmail}>
                Send
              </StyledButton>
            </Box>
            <Box>
              <Typography component="div" variant="body1" sx={{ py: 1 }}>
                If not click go back
              </Typography>
              <StyledButton
                variant="contained"
                onClick={() => navigate("/grm/admin")}
              >
                Return to admin
              </StyledButton>
            </Box>
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default Verify;
