import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Paper,
} from "@mui/material";
import Logo from "../../assets/images/green_river-removebg-preview 1.png";
import Caption from "../../components/shared/Caption";
import { StyledButton } from "../../styles/Button";
import axios from "../../api/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import FullScreenLoader from "../../components/shared/FullScreenLoader";
import { loadStripe } from "@stripe/stripe-js";
import ErrorMessage from "../../components/shared/ErrorMessage";
const Pay = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [bill, setBill] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(null);
  console.log(value, "value");
  useEffect(() => {
    getBillDetails();
  }, [id]);
  const getBillDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`/file-processing/detail`, {
        accountNo: id,
      });
      setBill(response.data.data);
      setValue(response.data.data.paperBill);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setBill({});
      console.log(e);
    }
  };
  const onRadioChange = (event) => {
    const val = event.target.value === "true" ? true : false;
    console.log(val, "val");
    setValue(val);
  };
  // console.log(
  //   process.env.REACT_APP_STRIPE_PUB_KEY,
  //   "process.env.REACT_APP_STRIPE_PUB_KEY"
  // );
  const onPayBill = async () => {
    setIsLoading(true);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

    const body = {
      ...bill,
    };
    try {
      const response = await axios.post("/create-checkout-session", body);
      const session = response.data.id;
      console.log(session);
      localStorage.setItem("stripeId", session);
      const result = stripe.redirectToCheckout({
        sessionId: session,
      });
      console.log(result, "resultresultresult");
      setIsLoading(false);

      if (result.error) {
        console.log(result.error);
      }
      const res = await axios.patch(`/file-processing/${bill._id}`, {
        paperBill: value,
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container
        maxWidth="xs"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </Box>
        <StyledButton onClick={() => navigate("/")} variant="contained">
          Go to Home
        </StyledButton>
        {!isLoading && (
          <>
            {Object.keys(bill).length > 0 ? (
              <Box
                sx={{
                  py: 1,
                  "& .MuiTypography-root": {
                    textAlign: "center",
                  },
                }}
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  sx={{ py: 2 }}
                >
                  <Caption title="Account Number :" bold />
                  <Typography component="div" variant="h6">
                    {bill.accountNo || ""}
                  </Typography>
                </Stack>
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  sx={{ py: 2 }}
                >
                  <Caption title="Total Bill :" bold />
                  <Typography component="div" variant="h6">
                    $ {bill.totalDue || ""}
                  </Typography>
                </Stack>
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  sx={{ py: 2 }}
                >
                  <Caption
                    title="Click here to view or save your current bill"
                    bold
                  />
                  <Typography component="div" variant="h6">
                    {bill.pdfName || ""}
                  </Typography>
                  <Link to={bill.attachment} target={"_blank"}>
                    <StyledButton variant="contained">View</StyledButton>
                  </Link>
                </Stack>
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={1}
                  sx={{ py: 2 }}
                >
                  <Caption title="Receiving a paper bill" bold />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      row
                      sx={{
                        justifyContent: "center",
                        "& .Mui-checked": {
                          color: "var(--main-color) !important",
                        },
                      }}
                      value={value}
                      onChange={onRadioChange}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {value ? (
                      <ErrorMessage error="You currently have selected to receive a paper bill along with an email PDF" />
                    ) : (
                      <ErrorMessage error="You currently have selected to not receive a paper bill email only" />
                    )}
                  </FormControl>
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <StyledButton variant="contained" onClick={onPayBill}>
                    Pay
                  </StyledButton>
                </Box>
              </Box>
            ) : (
              <Paper
                sx={{
                  padding: "16px",
                  borderRadius: "8px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  textAlign: "center",
                  backgroundColor: "#f7f7f7",
                }}
              >
                <Typography variant="h5" color="textSecondary">
                  No Data Available
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  There is no data to display at the moment.
                </Typography>
              </Paper>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Pay;
