import React from "react";
import { Typography, Button, Container, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../styles/Button";
const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="h4" sx={{ color: "var(--main-color)" }}>
            Unauthorized
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            You do not have access to the requested page.
          </Typography>
        </Grid>
        <Grid item>
          <StyledButton
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={goBack}
          >
            Go Back
          </StyledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Unauthorized;
