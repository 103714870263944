import { Routes, Route } from "react-router-dom";
import Layout from "./components/shared/Layout";
import RequireAuth from "./components/auth/RequireAuth";
import PersistLogin from "./components/auth/PersistLogin";
import Unauthorized from "./pages/auth/Unauthorized";
import Login from "./pages/auth/Login";
import Admin from "./pages/admin/Admin";
import Bill from "./pages/user/Home";
import Missing from "./pages/404/Missing";
import Customers from "./pages/admin/Customers";
import Verify from "./pages/admin/Verify";
import Pay from "./pages/user/Pay";
import Payment from "./pages/user/Payment";
import ChangePassword from "./pages/admin/ChangePassword";
// const ROLES = {
//   User: 2001,
//   Editor: 1984,
//   Admin: 5150,
// };
const ROLES = {
  User: "user",
  Admin: "admin",
  Editor: "editor",
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Bill />} />
        <Route path="/admin/grm/change-password" element={<ChangePassword />} />
        <Route path="/pay/:id" element={<Pay />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/grm/login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* <Route path="/grm/verify" element={<Verify />} /> */}

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/grm/admin" element={<Admin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/grm/customers" element={<Customers />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/grm/verify" element={<Verify />} />
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
