import React, { useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import Background from "../../assets/images/login.png";
import { Lock, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { loginSchema } from "../../schema";
import { StyledLoadingButton } from "../../styles/Button";
import useAuth from "../../hooks/useAuth";
import Logo from "../../assets/images/green_river-removebg-preview 1.png";
import axios from "../../api/axios";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorMessage from ".././../components/shared/ErrorMessage";
const LOGIN_URL = "/login";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setErrMsg("");
      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({ email: values.email, pwd: values.password }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        console.log(JSON.stringify(response?.data));
        //console.log(JSON.stringify(response));
        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;
        setAuth({
          email: values.email,
          pwd: values.password,
          roles,
          accessToken,
        });
        navigate("/grm/admin", { replace: true });
        // navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
      }
    },
  });
  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={12}
          md={7}
          sx={{
            backgroundImage: "url(" + Background + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "cover", lg: "contain" },
            backgroundColor: "#fff",
          }}
        >
          {/* <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: "0rem",
              left: "1rem",
              // background: "#7b72de",
              maxWidth: "26rem",
              textAlign: "center",
              paddingTop: "3rem",
              px: 2,
              color: "#fff",
            }}
          >
            <Typography variant="h5" component="div">
              New here?
            </Typography>
            <Typography variant="body" component="div">
              Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since.
            </Typography>
            <Button
              variant="contained"
              sx={{
                my: 2,
                background: "transparent",
                border: "2px solid #fff",
                borderRadius: "25px",
                "&:hover": {
                  background: "transparent",
                  opacity: 0.7,
                },
              }}
            >
              Sign up
            </Button>
          </Box>
        </Box> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          elevation={6}
          square
          sx={{
            background: "#fff",
            px: 3,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={Logo}
              alt=""
              style={{ width: "100%", maxWidth: "200px" }}
            />
            <Typography component="h1" variant="h4" className="text fw_bold">
              Sign in
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="email"
                name="email"
                // autoComplete="off"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                sx={{
                  my: 2,
                  background: "#f1f1f1",
                  borderRadius: "36px",
                  fieldset: {
                    border: "none",
                  },
                  input: {
                    padding: "15px",
                    "&::placeholder": {
                      color: "var(--grey-color) !important",
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    position: "absolute",
                    bottom: "-24px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Person sx={{ color: "var(--grey-color)" }} />
                  ),
                }}
              />
              <TextField
                fullWidth
                id="password"
                name="password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                sx={{
                  my: 2,
                  background: "#f1f1f1",
                  borderRadius: "36px",
                  fieldset: {
                    border: "none",
                  },
                  input: {
                    padding: "15px",
                    "&::placeholder": {
                      color: "var(--grey-color) !important",
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    position: "absolute",
                    bottom: "-24px",
                  },
                }}
                InputProps={{
                  startAdornment: <Lock sx={{ color: "var(--grey-color)" }} />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledLoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 1,
                    mb: 2,
                    width: "40%",
                    borderRadius: "20px",
                    "&.MuiLoadingButton-loading": {
                      color: "transparent  !important",
                    },
                    svg: {
                      color: "var(--main-color)",
                    },
                  }}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                >
                  Sign In
                </StyledLoadingButton>
              </Box>
            </form>

            {/* {loading && <div className="progress">Loading....</div>} */}
            {{ errMsg } && <ErrorMessage error={errMsg} />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
