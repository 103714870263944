import React from "react";
import { Typography } from "@mui/material";
const ErrorMessage = ({ error }) => {
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{ color: "red", fontSize: "0.8rem", my: 2 }}
    >
      {error}
    </Typography>
  );
};

export default ErrorMessage;
