import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  Button,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { billSchema } from "../../schema";
import { useFormik } from "formik";
import { StyledTextField } from "../../styles/TextField";
import Logo from "../../assets/images/green_river-removebg-preview 1.png";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { StyledLoadingButton } from "../../styles/Button";
const Home = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };
  const formik = useFormik({
    initialValues: {
      account_num: "",
      address: "",
    },
    validationSchema: billSchema,
    onSubmit: async (values) => {
      setError(false);
      setIsSubmit(true);
      try {
        const response = await axios.post(`/file-processing/detail`, {
          accountNo: values.account_num.trim(),
        });
        navigate(`/pay/${values.account_num}`);
        console.log(response, "response ");
        setIsSubmit(false);
      } catch (e) {
        setError(true);
        setIsSubmit(false);
        console.log(e);
      }
      //   alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Your account number is invalid!
        </Alert>
      </Snackbar>
      <Container
        maxWidth="xs"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </Box>
        <Typography
          component="div"
          variant="h5"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Welcome to Green River
        </Typography>
        <Box
          sx={{
            py: 3,
            "& .MuiTypography-root": {
              textAlign: "center",
            },
          }}
        >
          <Typography component="div" variant="body1">
            Mutual Water Company
          </Typography>
          <Typography component="div" variant="body1">
            Online Bill Pay
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ py: 2 }}>
              <InputLabel sx={{ color: "#000" }}>Account Number</InputLabel>
              <StyledTextField
                fullWidth
                name="account_num"
                value={formik.values.account_num}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.account_num &&
                  Boolean(formik.errors.account_num)
                }
                helperText={
                  formik.touched.account_num && formik.errors.account_num
                }
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ py: 2 }}>
              <InputLabel sx={{ color: "#000" }}>Service Address</InputLabel>
              <StyledTextField
                fullWidth
                name="address"
                type="text"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ pt: 7 }}>
              <StyledLoadingButton
                variant="contained"
                fullWidth
                type="submit"
                loading={isSubmit}
              >
                Submit
              </StyledLoadingButton>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default Home;
