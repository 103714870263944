import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Grid } from "@mui/material";
import { StyledButton } from "../../styles/Button";
const NotFound = () => {
  return (
    <Container>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "80vh" }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography variant="h1" gutterBottom>
            404
          </Typography>
          <Typography variant="h4" paragraph>
            Page not found
          </Typography>
          <Typography variant="body1" paragraph>
            The page you are looking for might have been removed or is
            temporarily unavailable.
          </Typography>
          <StyledButton variant="contained" component={Link} to="/">
            Go to Home
          </StyledButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
