import React, { useState } from "react";
import { Switch } from "@mui/material";
import { axiosPrivate } from "../../../api/axios";
import FullScreenLoader from "../../shared/FullScreenLoader";
const Switcher = ({ isLink, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitch, setIsSwitch] = useState(isLink);

  const onSwitchChange = async (event) => {
    setIsLoading(true);
    setIsSwitch(event.target.checked);
    try {
      const res = await axiosPrivate.put("/file-processing/update/link", {
        _id: id,
        isLink: event.target.checked,
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Switch
        checked={isSwitch}
        onChange={onSwitchChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </>
  );
};

export default Switcher;
