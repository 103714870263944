import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import Dashboard from "../../components/layout/Dashboard";
import { StyledButton, StyledLoadingButton } from "../../styles/Button";
import Modal from "../../components/shared/Modal";
import { CloudUpload, Delete, Edit } from "@mui/icons-material";
import ErrorMessage from "../../components/shared/ErrorMessage";
import Caption from "../../components/shared/Caption";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "../../components/shared/FullScreenLoader";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const Upload = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const excelInputRef = useRef();
  const pdfInputRef = useRef();
  const [error, setError] = useState("");
  const [pdfFilesList, setPdfFilesList] = useState([]);
  const [excelFileData, setExcelFileData] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [emailDetails, setEmailDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  // console.log(emailDetails, "excelFileData");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    fetchEmailDetails(controller.signal);

    // Set up an interval to call fetchEmailDetails every 100 milliseconds
    const intervalId = setInterval(() => {
      if (isMounted) {
        fetchEmailDetails(controller.signal);
      } else {
        clearInterval(intervalId); // Clear the interval if the component is unmounted
      }
    }, 10000); // 100 milliseconds

    // Cleanup the interval and abort the fetch if the component unmounts
    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(intervalId);
    };
  }, []);

  const fetchEmailDetails = async (signal) => {
    try {
      const { data } = await axiosPrivate.get("/file-processing", {
        signal,
      });
      setEmailDetails(data.data);
      console.log(data.data, "data");
    } catch (e) {
      setEmailDetails({});
      console.log(e, "e");
    }
  };
  const handleExcelFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        const reader = new FileReader();
        setExcelFile(file);
        reader.onload = (e) => {
          setExcelFileData(e.target.result);
        };
        reader.readAsArrayBuffer(file);
        setError("");
      } else {
        setExcelFile(null);
        setError("Please select a valid Excel file (with .xlsx extension).");
      }
    }
  };

  const handlePdfFileUpload = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const newPdfFilesList = Array.from(files);
      setPdfFilesList((prevPdfFilesList) => [
        ...prevPdfFilesList,
        ...newPdfFilesList,
      ]);
      setError("");
      pdfInputRef.current.value = null;
    } else {
      pdfInputRef.current.value = null;
      setError("Please select PDF files.");
    }
  };

  const getRowCount = (fileData) => {
    const workbook = XLSX.read(fileData, { type: "array" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    return XLSX.utils.sheet_to_json(worksheet).length;
  };
  const deletePdfFile = (index) => {
    const updatedPdfFilesList = [...pdfFilesList];
    updatedPdfFilesList.splice(index, 1);
    setPdfFilesList(updatedPdfFilesList);
  };
  const editPdfFile = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf";

    fileInput.onchange = async (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const updatedPdfFilesList = [...pdfFilesList];
        updatedPdfFilesList[index] = selectedFile;
        setPdfFilesList(updatedPdfFilesList);
      }
    };

    // Trigger the file input dialog
    fileInput.click();
  };
  const generatePdfFiles = async () => {
    setIsLoading(true);
    setIsSubmit(false);
    setError("");

    try {
      if (excelFileData && pdfFilesList.length === getRowCount(excelFileData)) {
        const formData = new FormData();
        const files = [];

        // Append the Excel file to the FormData
        formData.append("files", excelFile);
        pdfFilesList.forEach((pdfFile, index) => {
          console.log(pdfFile, "pdfFile");
          formData.append("files", pdfFile);
          // formData.append(`files[${index}]`, pdfFile);
          // files.push(pdfFile);
          // console.log(pdfFile, "pdfFile");
          // formData.append(pdfFile.name, pdfFile);
          // formData.append(`pdfFile${index}`, pdfFile);
        });
        console.log(formData, "files");
        // formData.append("pdf", JSON.stringify(files));
        // Make an HTTP POST request to your backend API
        const response = await axiosPrivate.post("/file-processing", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        });
        await fetchEmailDetails();
        setOpen(false);
        setIsLoading(false);
        // console.log("Files uploaded successfully:", response.data);
        setPdfFilesList([]);
        setExcelFileData(null);
        setExcelFile(false);
        setError("");
        setIsSubmit(true);
      } else {
        setIsLoading(false);
        setError(
          "Number of PDF files should match the number of rows in the Excel file."
        );
      }
    } catch (e) {
      console.error("Error uploading files:", e);
      setError("Error uploading files. Please try again.");
      setIsSubmit(false);
    }
  };

  const handleDownloadfiles = async (type) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/file-processing/${type}`, {
        responseType: "blob",
      });
      // Create a Blob from the API response
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create an invisible anchor element for downloading
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "excel-file.xlsx"; // Specify the desired filename

      // Simulate a click event to trigger the download
      link.click();

      // Clean up by revoking the Blob URL
      window.URL.revokeObjectURL(blobUrl);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e, "e");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSubmit(false);
  };
  return (
    <>
      <Dashboard>
        {isLoading && <FullScreenLoader />}

        <Snackbar open={isSubmit} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            Data uploaded successfully
          </Alert>
        </Snackbar>

        <Box>
          <Typography component="div" variant="body1" sx={{ py: 1 }}>
            There are currently <b>{emailDetails.totalUnsentEmails || 0} </b>{" "}
            email addresses in the queue with a due date of:{" "}
            <b>{emailDetails.dueDate} </b>
          </Typography>
          <Typography component="div" variant="body1" sx={{ py: 1 }}>
            Email were last sent on
            <b> {emailDetails.lastEmailData} </b>
          </Typography>

          <Box>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              Click here to <b>UPLOAD </b> new data and PDF's
            </Typography>
            <StyledButton variant="contained" onClick={() => setOpen(true)}>
              Upload
            </StyledButton>
          </Box>
          <Box>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              Click here to <b>Send </b> the current list
            </Typography>
            <StyledButton
              variant="contained"
              onClick={() => navigate("/grm/verify")}
            >
              Send
            </StyledButton>
          </Box>
          {/* <Box>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              Click here to <b>Download</b> last email data
            </Typography>
            <StyledButton
              variant="contained"
              onClick={() => handleDownloadfiles("download-excel-single")}
            >
              Download
            </StyledButton>
          </Box> */}
          <Box>
            <Typography component="div" variant="body1" sx={{ py: 1 }}>
              Click here to <b>Download</b> the last list of emails sent
            </Typography>
            <StyledButton
              variant="contained"
              onClick={() => handleDownloadfiles("download-excel-multiple")}
            >
              Download
            </StyledButton>
          </Box>
        </Box>
        <Modal open={open} setOpen={setOpen}>
          <Box>
            <Box sx={{ py: 1 }}>
              <Typography variant="h5" sx={{ pb: 1 }}>
                Select your excel file
              </Typography>
              <StyledButton
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                href="#file-upload"
                sx={{ mr: 2 }}
              >
                Upload
                <input
                  type="file"
                  className="d-none"
                  accept=".xlsx"
                  onSelect={() => {
                    excelInputRef.current.value = null; // Reset the input value
                  }}
                  onChange={handleExcelFileUpload}
                  ref={excelInputRef}
                />
              </StyledButton>
              {excelFile ? (
                <>
                  <Caption title={excelFile.name} />
                </>
              ) : (
                <Caption title="No file choosen" />
              )}
            </Box>
            <Box sx={{ py: 1 }}>
              <Typography variant="h5" sx={{ pb: 1 }}>
                Select your PDF's
              </Typography>
              <StyledButton
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
                href="#file-upload"
                sx={{ mr: 2 }}
              >
                Upload
                <input
                  ref={pdfInputRef}
                  type="file"
                  className="d-none"
                  accept=".pdf"
                  multiple
                  onChange={handlePdfFileUpload}
                  onSelect={() => {
                    pdfInputRef.current.value = null; // Reset the input value
                  }}
                />
              </StyledButton>
              {pdfFilesList.length > 0 ? (
                <>
                  <Caption title={`${pdfFilesList.length} files`} />
                </>
              ) : (
                <Caption title="No file choosen" />
              )}
            </Box>
            <Box>
              <h2>PDF's</h2>
              <Grid
                container
                spacing={2}
                sx={{ maxHeight: "15rem", overflowY: "auto" }}
              >
                {pdfFilesList.length > 0 && (
                  <>
                    {pdfFilesList.map((file, index) => (
                      <>
                        <Grid xs={12} md={6} item>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Caption title={file.name} />
                            <IconButton aria-label="delete" color="error">
                              <Delete onClick={() => deletePdfFile(index)} />
                            </IconButton>
                            <IconButton aria-label="edit" color="secondary">
                              <Edit onClick={() => editPdfFile(index, file)} />
                            </IconButton>
                          </Box>
                        </Grid>
                      </>
                    ))}
                  </>
                )}
              </Grid>

              <Box
                sx={{
                  py: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <StyledLoadingButton
                  onClick={generatePdfFiles}
                  variant="contained"
                  loading={isLoading}
                  disabled={isLoading}
                  sx={{ mr: 1 }}
                >
                  Submit
                </StyledLoadingButton>
                <StyledButton
                  onClick={() => setOpen(false)}
                  variant="contained"
                >
                  Close
                </StyledButton>
              </Box>

              {error && <ErrorMessage error={error} />}
            </Box>
          </Box>
        </Modal>
      </Dashboard>
    </>
  );
};

export default Upload;
