import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Typography } from "@mui/material";

const SendingEmail = ({ setEmailSent, emailSent }) => {
  const axiosPrivate = useAxiosPrivate();
  const [emailDetails, setEmailDetails] = useState({});
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    fetchEmailDetails(controller.signal);

    // Set up an interval to call fetchEmailDetails every 100 milliseconds
    const intervalId = setInterval(() => {
      if (isMounted) {
        fetchEmailDetails(controller.signal);
      } else {
        clearInterval(intervalId); // Clear the interval if the component is unmounted
      }
    }, 10000); // 100 milliseconds

    // Cleanup the interval and abort the fetch if the component unmounts
    return () => {
      isMounted = false;
      controller.abort();
      clearInterval(intervalId);
    };
  }, []);
  const fetchEmailDetails = async (signal) => {
    // setEmailSent(false);
    try {
      const { data } = await axiosPrivate.get("/file-processing/count", {
        signal,
      });
      setEmailSent(false);
      setEmailDetails(data.data);
      console.log(data.data, "data");
    } catch (e) {
      console.log(e.response);
      setEmailDetails(e.response.data.data);
      setEmailSent(true);
      console.log(e, "e");
    }
  };
  return (
    <>
      {emailSent ? (
        <>
          <Typography component="div" variant="h5">
            You have successfully sent {emailDetails.totalEmail} emails
          </Typography>
          <Typography component="div" variant="body1" sx={{ py: 1 }}>
            <b>{emailDetails.totalEmail} </b>
            emails with due date of {emailDetails.dueDate} <br />
          </Typography>
          <Typography component="div" variant="body1" sx={{ py: 1 }}>
            <b>Email on</b> {emailDetails.lastEmailData}
          </Typography>
        </>
      ) : (
        <>
          <Typography component="div" variant="h5">
            Sending {emailDetails.totalEmail} emails...{" "}
            {emailDetails.totalEmail - emailDetails.totalSendEmail} emails left
          </Typography>
        </>
      )}
    </>
  );
};

export default SendingEmail;
