import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { ErrorOutline } from "@mui/icons-material";
import { StyledButton } from "../../styles/Button";
import axios from "../../api/axios";
const Payment = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const stripe = localStorage.getItem("stripeId");
  const id = searchParams.get("id");
  useEffect(() => {
    if (status === "success") {
      onSuccessPayment();
    }
  }, [status]);

  const onSuccessPayment = async () => {
    try {
      const response = await axios.put(`/file-processing/${id}`, {
        stripeId: stripe,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const renderSuccessContent = () => (
    <>
      <CheckCircleOutline style={{ fontSize: "100px", color: "green" }} />
      <Typography variant="h4" gutterBottom>
        Payment Successful
      </Typography>
      <Typography variant="body1" paragraph>
        Your payment has been processed successfully.
      </Typography>
      <StyledButton variant="contained" href="/">
        Back to Home Page
      </StyledButton>
    </>
  );

  const renderErrorContent = () => (
    <>
      <ErrorOutline style={{ fontSize: "100px", color: "red" }} />
      <Typography variant="h4" gutterBottom>
        Payment Error
      </Typography>
      <Typography variant="body1" paragraph>
        There was an error processing your payment.
      </Typography>
      <StyledButton variant="contained" href="/">
        Back to Home Page
      </StyledButton>
    </>
  );
  return (
    <>
      <Container
        maxWidth="sm"
        style={{ textAlign: "center", marginTop: "50px" }}
      >
        {status === "success" ? renderSuccessContent() : renderErrorContent()}
      </Container>
    </>
  );
};

export default Payment;
