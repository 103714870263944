import React, { useState, useEffect } from "react";
import Dashboard from "../../components/layout/Dashboard";
import axios from "../../api/axios";
import {
  Box,
  FormControl,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  IconButton,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import { Link } from "react-router-dom";
import FullScreenLoader from "../../components/shared/FullScreenLoader";
import { Check, Close, FileCopy, RemoveRedEye } from "@mui/icons-material";
import { StyledButton } from "../../styles/Button";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Switcher from "../../components/admin/customers/Switcher";
const Customers = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  const [page, setPage] = useState(0); // Start with page 1
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState("all");
  const [totalRows, setTotalRows] = useState(0); // To store the total number of rows

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const fetchData = async () => {
      setLoading(true); // Set loading to true before making the API request
      try {
        const response = await axiosPrivate.get(
          `/file-processing/detail?page=${
            page + 1
          }&rowsPerPage=${rowsPerPage}&filter=${filter}`,
          {
            signal: controller.signal,
          }
        );
        // console.log(response.data, "response");
        setData(response.data.data);
        setTotalRows(response.data.totalItems); // Assuming your API sends the total count
        setLoading(false); // Set loading to false when data is received
      } catch (e) {
        setLoading(false); // Set loading to false when data is received
        setData([]);
        setTotalRows(0);
        console.log(e);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [page, rowsPerPage, filter]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to page 1 when changing rows per page
  };

  // const handleFilterChange = (event) => {
  //   const value = event.target.value;
  //   setFilter(value);
  // };
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilter(value);
    setPage(0); // Reset to page 1 when changing rows per page
  };

  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id);
  };

  return (
    <>
      <Dashboard>
        <FormControl
          variant="outlined"
          sx={{ marginBottom: "16px", width: "100%", maxWidth: "300px" }}
        >
          <InputLabel
            id="filter-label"
            sx={{ color: "rgba(0, 0, 0, 0.54) !important" }}
          >
            Select filter
          </InputLabel>
          <Select
            labelId="filter-label"
            id="filter"
            value={filter}
            onChange={handleFilterChange}
            label="Select filter"
            fullWidth
            sx={{
              fieldset: {
                borderColor: "rgba(0, 0, 0, 0.23) !important",
              },
              legend: {
                color: "rgba(0, 0, 0, 0.23) !important",
              },
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="sent">Emails Sent</MenuItem>
            <MenuItem value="notsent">Emails Not Sent</MenuItem>
            {/* Add more options dynamically if needed */}
          </Select>
        </FormControl>
        {loading ? (
          <FullScreenLoader /> // Display a loader while data is being fetched
        ) : data.length === 0 ? (
          <p>No data found.</p> // Display a message when data is empty
        ) : (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="left">Total Bill</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center">Email Status</TableCell>
                    <TableCell align="center">Account Number</TableCell>
                    <TableCell align="center">Paper Bill</TableCell>
                    <TableCell align="center">Due Date</TableCell>
                    <TableCell align="center">Stripe Id</TableCell>
                    <TableCell align="center">Paid</TableCell>
                    <TableCell align="center">Link</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.email ? row.email : "No Email"}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          ${" "}
                          {row?.totalDue
                            ? Number(row?.totalDue?.toFixed(2))
                            : "0"}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {row.propertyAddress}
                        </TableCell>
                        <TableCell align="center">
                          {row.isEmailSend ? (
                            <Check sx={{ color: "green" }} />
                          ) : (
                            <Close sx={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.accountNo ? row.accountNo : "No Account Number"}
                        </TableCell>
                        <TableCell align="center">
                          {row.paperBill ? (
                            <Check sx={{ color: "green" }} />
                          ) : (
                            <Close sx={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell align="center">{row.dueDate}</TableCell>
                        <TableCell align="center">
                          {row.stripeId ? (
                            <>
                              <Tooltip title={row.stripeId} arrow>
                                <Typography noWrap>
                                  {row.stripeId.slice(0, 5) +
                                    "..." +
                                    row.stripeId.slice(-5)}
                                  <IconButton
                                    onClick={() =>
                                      copyToClipboard(row.stripeId)
                                    }
                                    size="small"
                                    sx={{ ml: 1, cursor: "pointer" }}
                                    color="primary"
                                  >
                                    <FileCopy
                                      fontSize="small"
                                      sx={{ color: "var(--main-color)" }}
                                    />
                                  </IconButton>
                                </Typography>
                              </Tooltip>
                            </>
                          ) : (
                            "No Stripe Id"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.isPayed ? (
                            <Check sx={{ color: "green" }} />
                          ) : (
                            <Close sx={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Switcher isLink={row.isLink} id={row._id} />
                        </TableCell>
                        <TableCell align="right">
                          <Link to={row.attachment} target={"_blank"}>
                            <Tooltip title="View PDF">
                              <IconButton>
                                <RemoveRedEye
                                  sx={{ color: "var(--main-color)" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ py: 4 }}>
              {/* this starts from page 1 */}
              {/* <Pagination
                count={Math.ceil(totalRows / rowsPerPage)}
                color="primary"
                page={page}
                onChange={handlePageChange}
              /> */}
              {/* this starts from page 0 */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          </div>
        )}
      </Dashboard>
    </>
  );
};

export default Customers;
