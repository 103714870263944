import React, { useState, useEffect } from "react";
import {
  LocalPoliceOutlined,
  Flag,
  Email,
  ArrowBackIosNew,
  ArrowForwardIos,
  Home,
  SupportAgent,
  Lock,
} from "@mui/icons-material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Logo from "../../assets/images/green_river-removebg-preview 1.png";
import {
  Box,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Stack,
  CssBaseline,
  Drawer,
  Divider,
} from "@mui/material";
import { StyledButton } from "../../styles/Button";
import useLogout from "../../hooks/useLogout";
import FullScreenLoader from "../shared/FullScreenLoader";
const drawerWidth = 251;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, mobilescreen }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: mobilescreen ? 0 : `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, mobilescreen }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: mobilescreen ? "100%" : `calc(100% - ${drawerWidth}px)`,
    marginLeft: mobilescreen ? 0 : `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
var menuLinks = [
  {
    name: "Home",
    navigate: "/grm/admin",
    icon: <Home />,
  },
  {
    name: "Customers",
    navigate: "/grm/customers",
    icon: <SupportAgent />,
  },
];
const Dashboard = ({ children }) => {
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const location = useLocation();
  const logout = useLogout();
  const mobilescreen = useMediaQuery(muiTheme?.breakpoints?.down("md"));
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const signOut = async () => {
    setIsLoading(true);
    try {
      await logout();
      navigate("/grm/login");
      setIsLoading(false);
    } catch (e) {
      console.log(e, "e");
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Box sx={{ display: "flex", mt: 2 }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          className="app-bar"
          sx={{
            margin: 0,
            p: 0,
            zIndex: mobilescreen ? 1 : 1100,
            background: "var(--main-color)",
          }}
          mobilescreen={mobilescreen}
        >
          <Stack
            direction={mobilescreen ? "row" : "row"}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ mr: 5 }}
          >
            <Toolbar className="toolbar" sx={{ position: "relative" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
              >
                {open ? <ArrowBackIosNew /> : <ArrowForwardIos />}
              </IconButton>
              <Typography
                variant="h5"
                noWrap
                component="div"
                className="text"
                sx={{
                  ml: 3,
                  color: "#fff",
                  fontWeight: 600,
                }}
              >
                Admin Panel
              </Typography>
            </Toolbar>

            <StyledButton
              sx={{ background: "#fff !important", color: "var(--main-color)" }}
              variant="contained"
              onClick={signOut}
            >
              Logout
            </StyledButton>
          </Stack>
        </AppBar>
        <Drawer
          variant={mobilescreen ? "temporary" : "persistent"}
          anchor="left"
          className="sidebar "
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            zIndex: 1,
            border: "transparent",
            position: "relative",
          }}
        >
          {/* <DrawerHeader className="sidebar">

                </DrawerHeader> */}
          <Box>
            <Box onClick={() => navigate("/upload")}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={Logo} alt="logo" />
              </Box>
              <Divider />
            </Box>
            {/* <div style={{ padding: "0px 19px" }}>
              {" "}
              <hr />
            </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                height: "100%",
                // background: "green",
              }}
            >
              <Box sx={{ px: 2, height: "100%" }}>
                <List>
                  {menuLinks.map((link, index) => (
                    <>
                      <ListItemButton
                        selected={location.pathname === link.navigate}
                        key={link.name}
                        sx={{
                          border: "2px solid rgba(0, 0, 0, 0.54)",
                          borderRadius: "8px",
                          mt: 1.5,
                          "&.Mui-selected": {
                            color: "white",
                            background:
                              "linear-gradient(to right, var(--main-color), #099778)",
                            border: "2px solid var(--main-color)",
                            "& .MuiListItemIcon-root  , .MuiTypography-root": {
                              color: "#fff !important",
                            },
                          },
                        }}
                        disablePadding
                        className="sidebar-list"
                        onClick={() => navigate(link.navigate)}
                      >
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText
                          primary={link.name}
                          sx={{
                            "& .MuiTypography-root": {
                              fontWeight: "bold",
                              color: "rgba(0, 0, 0, 0.54)",
                            },
                          }}
                        />
                      </ListItemButton>
                    </>
                  ))}
                </List>
              </Box>
            </div>
          </Box>
        </Drawer>
        <Main
          open={open}
          sx={{ overflowX: "hidden" }}
          mobilescreen={mobilescreen}
        >
          <DrawerHeader />
          {children}
        </Main>
      </Box>
    </>
  );
};

export default Dashboard;
