import React, { useState } from "react";
import {
  Typography,
  Container,
  Box,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { changePasswordSchema } from "../../schema";
import { useFormik } from "formik";
import { StyledTextField } from "../../styles/TextField";
import Logo from "../../assets/images/green_river-removebg-preview 1.png";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { StyledLoadingButton } from "../../styles/Button";
const ChangePassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      oldPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      setError(false);
      setIsSubmit(true);
      try {
        const response = await axios.put(`/users/change-password`, {
          email: values.email.trim(),
          oldPassword: values.oldPassword.trim(),
          newPassword: values.password.trim(),
        });
        console.log(response, "response ");
        navigate("/grm/login");
        setError(true);
        setIsSubmit(false);
      } catch (e) {
        setError(false);
        setIsSubmit(false);
        console.log(e);
      }
      //   alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Password Changed!
        </Alert>
      </Snackbar>
      <Container
        maxWidth="xs"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={Logo} alt="logo" style={{ width: "100%" }} />
        </Box>
        <Typography
          component="div"
          variant="h5"
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Welcome to Green River
        </Typography>

        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ py: 1 }}>
              <InputLabel sx={{ color: "#000" }}> Email</InputLabel>
              <StyledTextField
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <InputLabel sx={{ color: "#000" }}>Old Password</InputLabel>
              <StyledTextField
                fullWidth
                name="oldPassword"
                type="password"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <InputLabel sx={{ color: "#000" }}>New password</InputLabel>
              <StyledTextField
                fullWidth
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <InputLabel sx={{ color: "#000" }}>Confirm Password</InputLabel>
              <StyledTextField
                fullWidth
                name="confirmPassword"
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>

            <Box sx={{ py: 5 }}>
              <StyledLoadingButton
                variant="contained"
                fullWidth
                type="submit"
                loading={isSubmit}
              >
                Reset Password
              </StyledLoadingButton>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default ChangePassword;
