import { Typography } from "@mui/material";
import React from "react";

const Caption = ({ title, bold = false }) => {
  return (
    <>
      <Typography
        variant="small"
        sx={{ color: "var(--main-color)", fontWeight: bold ? "bold" : 500 }}
      >
        {title && title}
      </Typography>
    </>
  );
};

export default Caption;
