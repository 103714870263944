import { Button } from "@mui/material";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
export const StyledButton = styled(Button)`
  background: var(--main-color);
  &:hover {
    background: var(--main-color);
    opacity: 0.9;
  }
`;
export const StyledLoadingButton = styled(LoadingButton)`
  background: var(--main-color);
  &:hover {
    background: var(--main-color);
    opacity: 0.9;
  }
`;
