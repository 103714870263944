import * as yup from "yup";
export const billSchema = yup.object({
  account_num: yup
    .string("Enter your account number")
    .required("Account number is required"),
  address: yup
    .string("Enter your service address")
    .required("Service address is required"),
});
export const changePasswordSchema = yup.object({
  oldPassword: yup.string().required("Old Password is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const loginSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
