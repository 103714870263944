import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
const FullScreenLoader = () => {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FullScreenLoader;
