import styled from "styled-components";
import { TextField } from "@mui/material";
export const StyledTextField = styled(TextField)`
  position: relative;
  & .MuiOutlinedInput-root {
    border-radius: 10px;
    /* border: 1px solid lightgray; */
    & .MuiOutlinedInput-input {
      padding: 12px 10px;
    }

    &:hover,
    &.Mui-focused {
      border-color: gray; // Change the border color on hover and focus
    }
    &.Mui-focused {
      outline: none; // Remove outline on focus
      border-color: none; // Change the border color on hover and focus
    }
    & .MuiFormHelperText-root {
      background: green !important;
    }
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
    left: -11px;
  }
  /* & fieldset {
    border: none;
  } */
`;
