import React from "react";
import { Dialog, DialogContent } from "@mui/material";
const Modal = ({ open, setOpen, children }) => {
  console.log(open, "open");
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
